import React from 'react';
// @ts-ignore
import imageAkquinet from '../../../static/assets/akquinet_logo.png';
// @ts-ignore
import imageHse from '../../../static/assets/HSE_logo.png';
import './footer.scss';
import {navigate} from "gatsby";


export default function Footer() {

    const navigateToImpressum = () => {
        navigate('/Impressum');
    }

    const navigateToPrivacyPolicy = () => {
        navigate('/PrivacyPolicy');
    }

    return (
        <main className="ContentFooter">

            <h1 className="HeadingContentFooter">
                Dieser Service wird angeboten von:
            </h1>

            <section className="CompanyLogos">
                <div className="Logo">
                    <a href="https://akquinet.com/gesundheitswesen.html">
                        <img className="LogoImage" src={imageAkquinet}/>
                    </a>
                </div>
                <div className="Logo">
                    <a href="https://www.hse365.at/qm">
                        <img className="LogoImage" src={imageHse}/>
                    </a>
                </div>
            </section>

            <section className="BottomLine">
                <div className="EntryBlockLeft">
                    <button className="PageButton" onClick={() => navigateToImpressum()}>Impressum</button>
                </div>
                <div className="EntryBlockLeft">
                    <button className="PageButton" onClick={() => navigateToPrivacyPolicy()}>Datenschutz</button>
                </div>
                <div className="Copyright">
                    2021 AKQUINET. &nbsp; All rights reserved
                </div>
            </section>
        </main>
    );
}